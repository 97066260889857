/**
 * There should be at least one case available.
 * If there is only one case, the word has the same form for singular and plural
 * If there are 2 cases, the first one must be the singular form, and the second one, plural
 */
export default (value, cases = []) => {
    if (!cases.length) {
        return '';
    }

    if (cases.length === 1) {
        return `${value} ${cases[0]}`;
    }

    const input = Math.abs(value);
    const correctCase = input === 1 ? cases[0] : cases[1];

    return `${value} ${correctCase}`
}
