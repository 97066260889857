require('./bootstrap')

import { createApp, defineAsyncComponent } from 'vue'

import formatDecimals from './filters/format-decimals'
import lowerCase from './filters/lowercase'
import capitalizeFirstLetter from './filters/capitalize-first-letter'
import pluralize from './filters/pluralize'
import eventHub from './event-hub'

import { tooltip, popover } from "./bootstrap-plugins";

import { vMaska } from 'maska'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'

const bootApp = (el) => {
    const app = createApp({})

    app.component('offers-page', defineAsyncComponent(() => import('./pages/OffersPage')))
       .component('offer-page', defineAsyncComponent(() => import('./pages/OfferPage')))
       .component('devices-page', defineAsyncComponent(() => import('./pages/DevicesPage')))
       .component('device-page', defineAsyncComponent(() => import('./pages/DevicePage')))
       .component('order-page', defineAsyncComponent(() => import('./pages/OrderPage')))
       .component('porting-order-page', defineAsyncComponent(() => import('./pages/PortingOrderPage')))
       .component('insurance-page', defineAsyncComponent(() => import('./pages/InsurancePage')))
       .component('import-page', defineAsyncComponent(() => import('./pages/admin/ImportPage')))
       .component('cancel-order-page', defineAsyncComponent(() => import('./pages/admin/CancelOrderPage')))
       .component('offer-search', defineAsyncComponent(() => import('./components/SubscriptionOfferSearch')))
       .component('renewal-check-modal', defineAsyncComponent(() => import('./components/modals/RenewalCheckModal')))
       .component('save-offer-modal', defineAsyncComponent(() => import('./components/modals/SaveOfferModal')))

    app.directive('maska', vMaska)
       .directive('tooltip', tooltip)
       .directive('popover', popover)

    app.config.globalProperties = {
        isMobile: window.matchMedia("(max-width: 767px)").matches,
        currencySymbol: '€',
        vat: 1.21,
        vatPercentage: 0.21,
        $filters: {
            formatDecimals,
            lowerCase,
            capitalizeFirstLetter,
            pluralize
        },
        $emitter: eventHub
    }

    app.mount(el)
}

const elements = document.querySelectorAll('.vue-app')

elements.forEach(el => {
    bootApp(el)
})
