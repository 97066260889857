export const tooltip = {
    mounted(el) {
        const tooltip = new bootstrap.Tooltip(el)
    }
}

export const popover = {
    mounted(el) {
        const popover = new bootstrap.Popover(el)
    }
}
