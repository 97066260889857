export default (value, glue = null) => {
    if (!value) {
        return ''
    }

    value = value.toString().toLowerCase()

    if (!glue) {
        return value
    }

    return value.replaceAll(' ', glue)
}
